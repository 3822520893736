<template>
    <v-container fluid class="fill-height primary">
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-form autocomplete="on" @submit="changepassword">
                    <v-card class="elevation-5 pa-3">
                        <div class="text-right">
                            <localization />
                        </div>
                        <v-card-text>
                            <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
                                {{ result }}
                            </v-snackbar>
                            <div class="layout column align-center">
                                <img src="img/icons/logo.png" alt="Logo Vitissia" width="241" height="115">
                                <h1 class="text-center my-4 primary--text">
                                    Réinitialisez votre mot de passe
                                </h1>
                            </div>
                            <v-text-field v-model="model.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                name="password" :label="$t('login.password')" :rules="[rules.required]"
                                :type="show1 ? 'text' : 'password'" required autocomplete="new-password"
                                @click:append="show1 = !show1" />
                            <v-text-field v-model="model.confirmPassword" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                name="confirm" :type="show2 ? 'text' : 'password'"
                                :rules="confirmPasswordRules.concat(passwordConfirmationRule)" :label="$t('login.confirm')"
                                required autocomplete="new-password" @click:append="(show2 = !show2)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn type="submit" color="primary" :loading="loading" block>
                                        Valider
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" text to="/signin" block>
                                        {{ $t('login.signIn') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" text to="/signup" block>
                                        {{ $t('login.signUp') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Localization from '../widget/AppLocalization.vue';

export default {
    name: 'RecupPassword',
    components: { Localization },
    data: () => ({
        loading: false,
        showResult: false,
        result: '',
        color: 'undefined',
        show1: false,
        show2: false,
        model: {
            password: '',
            confirmPassword: '',
        },
        confirmPasswordRules: [(v) => !!v || ''],
        rules: {
            required: (value) => !!value || 'Obligatoire.',
            email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Email invalide.';
            },
        },
    }),
    computed: {
        passwordConfirmationRule() {
            return () => this.model.password === this.model.confirmPassword || 'Les mots de passe doivent correspondre';
        }
    },
    methods: {
        async changepassword(e) {
            e.preventDefault();
            if (this.model.password === '' || this.model.confirmPassword === '') {
                this.showResult = true;
                this.color = 'red';
                this.result = 'Veuillez renseigner les champs nécessaires';
                return;
            }

            const reponse = await this.$store.dispatch('ChangePassword', {
                newpassword: this.model.password,
                newpasswordcheck: this.model.confirmPassword,
                token: this.$route.query.token,
                config: this.$config,
                axios: this.$axios
            });

            if (reponse.header === 'Succès') {
                this.result = 'Félicitation, vous avez réinitialiser votre mot de passe';
                this.color = 'green';
                this.showResult = true;

                setTimeout(() => {
                    this.$router.push(this.$route.query.redirect || '/');
                }, 5000);
            } else {
                this.error = true;
                this.result = 'votre demande de réinitialison à échoué à cause d\'un token déjà utilisé';
                this.showResult = true;
                this.color = 'red';
            }
        }
    },
};
</script>
